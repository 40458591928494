require("@rails/ujs").start()
require('jquery')
const WOW = require('wow.js')

import './css/style.css'
import './css/animate.min.css'
import './fonts/fonts.css'
import './css/media.css'

$(document).ready(function() {
  new WOW().init();

  var $form = $('.feedback__form');
  $form.on('submit', function(){
    $(".required").each(function(){
      if( $(this).val() == '')  {
        $(this).addClass("warring");
      } else {
        $(this).removeClass("warring");
      }
    })

    if ($(".required.warning").length) return false

    $.post($form.attr('action'), $form.serialize(), function(resp){
      if (resp.status == 'success') {
        $form.slideUp();
        $('.feedback__thanks').slideDown();
      }
    })
    return false;
  })
});
